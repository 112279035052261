import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/ui/button';

const Home = () => {
  return (
    <div className="">
      <div className='w-full min-h-screen flex justify-center'>
        <div className='md:max-w-lg max-w-md p-6 my-16 md:my-32 w-full'>
          <img src="/comet.png" className='w-10 mb-6' />
          <p className='text-2xl font-bold mb-8 leading-6 text-slate-900'>Enhance Human Coordination</p>
          <p className='text-lg font-medium mb-4 leading-6 text-slate-600'>
          Creativity flourishes within communities, empowering creators, inspiring members, and enabling world changing ideas to thrive. With Comet, unleash the power of your community.
          </p>

          <p className='text-lg font-bold mb-4 leading-6 text-slate-900 underline'>
            Products
          </p>

          <p className='text-lg font-medium mb-4 leading-6 text-slate-600'>
            <a href="https://atris.ai" target="_blank" className='text-blue-500 underline'>
              Atris
            </a> - An AI Community Manager 
          </p>

          <p className='text-lg font-medium mb-4 leading-6 text-slate-600'>
            <a href="https://comet-3.gitbook.io/comet-sdk/" target="_blank" className='text-blue-500 underline'>
              Comet SDK
            </a> - Build community enabled products faster
          </p>

          <p className='text-lg font-medium mb-4 leading-6 text-slate-600'>
            <a href="https://www.withcomet.com/c/insiders" target="_blank" className='text-blue-500 underline'>
              Community Platform
            </a> - Now on iOS
          </p>

          <p className='text-lg font-bold mb-4 leading-6 text-slate-900 underline'>
            Learn More
          </p>

          <p className='text-lg font-medium mb-4 leading-6 text-slate-600'>
            <a href="https://withcomet.notion.site/Welcome-to-Comet-922c738987b048bf804acdcf98a4f510" target="_blank" className='text-blue-500 underline'>
              Blog
            </a>
          </p>

          <p className='text-lg font-medium mb-4 leading-6 text-slate-600'>
            <a href="https://twitter.com/joincomet" target="_blank" className='text-blue-500 underline'>
              Twitter
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Home
